<template>
  <div class="meituan  content-index">
    <el-page-header @back="goBack" content="验券中心"></el-page-header>
    <div class="search">
      <el-input
        v-model.trim="meituanValue"
        ref="input"
        placeholder="请在此输入券码或用扫码枪扫描券码"
      ></el-input>
      <el-button
        type="primary"
        @click="enterCode"
        :disabled="!meituanValue.length"
        :loading="lookButton"
        >确 定</el-button
      >
    </div>
    <div>最新验券记录</div>
    <div class="table-box">
      <Table
        :current-value="pageData.current"
        :orientation="'center'"
        :serial-number="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        :height="'65vh'"
        :loading="loading"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <el-dialog
      :visible="meituanVisible"
      width="500px"
      class="meituanDialog"
      @close="closeVisible"
    >
      <template slot="title">
        <span v-if="codeShow == 1" style="font-size: 16px;color:#101010"
          >商品核销</span
        >
        <div v-if="codeShow == 2" style="display:flex;align-items: center;">
          <span class="el-icon-error" style="color:#F56C6C"></span>
          <span style="font-size: 16px;color:#101010;padding-left:10px"
            >验券失败</span
          >
        </div>
        <div v-if="codeShow == 3" style="display:flex;align-items: center;">
          <span class="el-icon-success" style="color:#67C23A"></span>
          <span style="font-size: 16px;color:#101010;padding-left:10px"
            >验券成功</span
          >
        </div>
      </template>
      <div style="color:#101010" v-if="codeShow == 1">
        <el-form ref="form" :model="meituanForm" label-width="200px">
          <el-form-item>
            <span slot="label">
              <span style="color:#101010">团购信息:</span>
            </span>
            <span>{{ meituanForm.dealTitle }}</span>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span style="color:#101010">剩余消费数量:</span>
            </span>
            <span>{{ meituanForm.count }}</span>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span style="color:#101010">消费数量:</span>
            </span>
            <el-input-number
              v-model="meituanForm.number"
              :min="1"
              :max="meituanForm.count"
              label="请输入数量"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div
        style="height:120px;display:flex;justify-content: center;align-items: center;font-size: 16px;"
        v-if="codeShow != 1"
      >
        <span>{{ text }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="closeVisible"
          :type="codeShow == 1 ? '' : 'primary'"
          >{{ codeShow == 1 ? "取 消" : "确 定" }}</el-button
        >
        <el-button
          type="primary"
          :loading="enterButton"
          @click="enterMeituan"
          v-if="codeShow == 1"
          >确定核销</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/table/index.vue";
import {
  getMeituanTradeOrderList,
  meituanScanCodeCoupon,
  consumeCoupon,
} from "@/api/meituan";
export default {
  components: {
    Table,
  },
  data() {
    return {
      meituanValue: "",
      loading: false,
      tableData: [],
      pageData: {
        current: 1,
        size: 10,
      },
      total: 0,
      titleListData: [
        {
          prop: "dealTitle",
          label: "商品信息",
        },
        {
          prop: "productTypeName",
          label: "商品类型",
        },
        {
          prop: "receiptCode",
          label: "券号",
        },
        {
          prop: "numPrice",
          label: "验证数量/验证金额",
        },
        {
          prop: "mobile",
          label: "用户手机",
        },
        {
          prop: "paySuccTime",
          label: "验证时间",
        },
        {
          prop: "storeName",
          label: "验证门店",
        },
      ],
      meituanForm: {},
      meituanVisible: false,
      codeShow: 1,
      lookButton: false,
      enterButton: false,
      text: "",
    };
  },
  created() {
    this.getMeituanTradeOrder();
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    // 返回
    goBack() {
      this.$router.back();
    },
    // 获取列表数据
    getMeituanTradeOrder() {
      this.loading = true;
      getMeituanTradeOrderList({ ...this.pageData })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records?.map((item) => {
              item.numPrice = item.count + "/" + item.dealPrice;
              return item;
            });
            this.total = data.data.total;
            this.loading = false;
          } else {
            this.$message.error(data.msg);
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 分页
    handleCurrentChange(value) {
      this.pageData.current = value;
      this.getMeituanTradeOrder();
    },
    // 确定扫码
    enterCode() {
      this.lookButton = true;
      meituanScanCodeCoupon(this.meituanValue)
        .then(({ data }) => {
          if (data.code == 0) {
            this.meituanVisible = true;
            this.lookButton = false;
            this.meituanForm = data.data[0];
            this.meituanValue = "";
          } else {
            this.$message.error(data.msg);
            this.lookButton = false;
          }
        })
        .catch(() => {
          this.lookButton = false;
        });
    },
    // 关闭弹框
    closeVisible() {
      this.meituanVisible = false;
      this.meituanForm = {};
      this.codeShow = 1;
      this.$refs.input.focus();
    },
    // 确定验券
    enterMeituan() {
      this.enterButton = true;
      consumeCoupon({
        receiptCode: this.meituanForm.receiptCode,
        count: this.meituanForm.number,
      })
        .then(({ data }) => {
          if (data.success == true) {
            this.codeShow = 3;
            this.text = data.msg;
            this.enterButton = false;
            this.getMeituanTradeOrder();
          } else {
            this.codeShow = 2;
            this.text = data.msg;
            this.enterButton = false;
          }
        })
        .catch(() => {
          this.enterButton = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.meituan {
  padding: 20px 30px;
  overflow: auto;
  .back {
    height: 40px;
    display: flex;
    font-size: 22px;
    .icon {
      height: 40px;
      line-height: 40px;
    }
    span:nth-child(2) {
      padding-left: 15px;
      height: 40px;
      line-height: 40px;
    }
  }
  .search {
    display: flex;
    padding: 20px 0;
    .el-input {
      width: 300px;
    }
  }
  .meituanDialog {
    ::v-deep .el-dialog__body {
      padding-top: 0;
    }
    .el-form {
      ::v-deep .el-form-item {
        margin: 0;
      }
    }
  }
}
</style>
