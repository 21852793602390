import request from '@/utils/request'

// 扫码验券信息
export function scanCodeCoupon(receiptCode) {
    return request({
        url: 'trilateral/meituanTuangou/scanCodeCoupon/'+receiptCode,
        method: 'get'
    })
}
// 分页查询美团已经核销的券
export function getMeituanTradeOrderList(params) {
    return request({
        url: 'trilateral/meituanTradeOrder/page',
        method: 'get',
        params
    })
}
// 扫码验券校验接口
export function meituanScanCodeCoupon(receiptCode) {
    return request({
        url: 'trilateral/meituanTuangou/scanCodeCoupon/'+receiptCode,
        method: 'get',
    })
}
// 门店验美团券接口
export function consumeCoupon(data) {
    return request({
        url: 'trilateral/meituanTuangou/store/consumeCoupon',
        method: 'post',
        data
    })
}